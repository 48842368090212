import { format } from 'date-fns';
import { graphql, Link } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import * as _ from 'lodash';
import { lighten, setLightness } from 'polished';
import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import PostContent from '../components/PostContent';
import { ReadNext } from '../components/ReadNext';
import { Subscribe } from '../components/subscribe/Subscribe';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import { colors } from '../styles/colors';
import config from '../website-config';
import { AuthorList } from '../components/AuthorList';
import { ShareButtons } from "../components/ShareButtons"
import { Guides } from "../components/Guides"

import "katex/dist/katex.min.css"

import {
  inner,
  outer,
  PostFeed,
  SiteDescription,
  SiteQuote,
  SiteHeader,
  SiteHeaderContent,
  SiteMain,
  SiteTitle,
  BookLink,
  SiteNavMain,
  SiteArchiveHeader,
  ResponsiveHeaderBackground,
  SiteHeaderBackground,
} from '../styles/shared';

const PageTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark;
  let width = '';
  let height = '';
  if (post.frontmatter.image?.childImageSharp) {
    width = post.frontmatter.image.childImageSharp.fluid.sizes.split(', ')[1].split('px')[0];
    height = String(Number(width) / post.frontmatter.image.childImageSharp.fluid.aspectRatio);
  } else {
    width = 600;
    height = 314;
  }

  const date = new Date(post.frontmatter.date);
  // 2018-08-20
  const datetime = format(date, 'yyyy-MM-dd');
  // 20 AUG 2018
  const displayDatetime = format(date, 'dd LLL yyyy');

  let linkNext = null;
  let titleNext = null;
  if(post.frontmatter.linkNext) {
    let n = post.frontmatter.linkNext;
    n = n.split('](');
    titleNext = n[0].substring(1);
    linkNext = n[1].slice(0, -1)
    //console.log(n, linkNext, titleNext);
  }

  let linkPrev = null;
  let titlePrev = null;
  if(post.frontmatter.linkPrev) {
    let n = post.frontmatter.linkPrev;
    n = n.split('](');
    titlePrev = n[0].substring(1);
    linkPrev = n[1].slice(0, -1)
    //console.log(n, linkNext, titleNext);
  }

  let youtube = null;
  let youtubeLink = null;
  if(post.frontmatter.youtube) {
    let y = post.frontmatter.youtube;
    youtube = "https://www.youtube.com/embed/" + y + "?rel=0&showinfo=0&modestbranding=1&autohide=1"
    youtubeLink = "https://youtu.be/" + y
  }

  let title = data.markdownRemark.frontmatter.title;
  let book = null;
  let booklink = null;

  if(data.markdownRemark.frontmatter.type === "text") {
    title = data.markdownRemark.frontmatter.excerpt;
    book = data.markdownRemark.frontmatter.title;
    booklink = `/texts/${_.kebabCase(book)}/`

    if(title == book) {
      book = null;
      booklink = null;
    }

    if(data.markdownRemark.frontmatter.parentPath != "" && data.markdownRemark.frontmatter.parentPath != null) {
      booklink = data.markdownRemark.frontmatter.parentPath;
    }
  }

  const twitterHandle = config.twitter.split('https://twitter.com/')[1];
  const url = location.href;
  const tags =  data.markdownRemark.frontmatter.tags || [];
  //console.log(title, twitterHandle, url, tags);

  //console.log( post.frontmatter)
  return (
    <IndexLayout className="post-template">
      <Helmet>
        <html lang={config.lang} />
        <title>{title + ' - ' + config.title}</title>

        <meta name="description" content={post.frontmatter.excerpt || post.excerpt} />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title + ' - ' + config.title} />
        <meta property="og:description" content={post.frontmatter.excerpt || post.excerpt} />
        <meta property="og:url" content={config.siteUrl + location.pathname} />
        {post.frontmatter.image?.childImageSharp ? (
          <meta
            property="og:image"
            content={`${config.siteUrl}${post.frontmatter.image.childImageSharp.fluid.src}`}
          />
        ) : (
          <meta
            property="og:image"
            content={`${config.siteUrl}${location.pathname}card.jpg`}
          />
        )}
        <meta property="article:published_time" content={post.frontmatter.date} />
        {/* not sure if modified time possible */}
        {/* <meta property="article:modified_time" content="2018-08-20T15:12:00.000Z" /> */}
        {post.frontmatter.tags && (
          <meta property="article:tag" content={post.frontmatter.tags[0]} />
        )}

        {config.facebook && <meta property="article:publisher" content={config.facebook} />}
        {config.facebook && <meta property="article:author" content={config.facebook} />}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title + ' - ' + config.title} />
        <meta name="twitter:description" content={post.frontmatter.excerpt || post.excerpt} />
        <meta name="twitter:url" content={config.siteUrl + location.pathname} />
        {post.frontmatter.image?.childImageSharp ? (
          <meta
            name="twitter:image"
            content={`${config.siteUrl}${post.frontmatter.image.childImageSharp.fluid.src}`}
          />
        ): (
         <meta
            name="twitter:image"
            content={`${config.siteUrl}${location.pathname}card.jpg`}
          />
        )}
        <meta name="twitter:label1" content="Written by" />
        <meta name="twitter:data1" content={post.frontmatter.author[0]} />
        <meta name="twitter:label2" content="Filed under" />
        {post.frontmatter.tags && <meta name="twitter:data2" content={post.frontmatter.tags[0]} />}
        {config.twitter && (
          <meta
            name="twitter:site"
            content={`@${config.twitter.split('https://twitter.com/')[1]}`}
          />
        )}
        {config.twitter && (
          <meta
            name="twitter:creator"
            content={`@${config.twitter.split('https://twitter.com/')[1]}`}
          />
        )}

        {width && <meta property="og:image:width" content={width} />}
        {height && <meta property="og:image:height" content={height} />}
      </Helmet>
      <Wrapper css={PostTemplate}>

        <header css={[outer, SiteNavMain]} style={{padding: '0 5vw'}} className="site-header" >
          <div css={inner}>
            <SiteNav isPost post={ {title: title }} />
          </div>
        </header>
        <main id="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            {/* TODO: no-image css tag? */}
            <article css={[PostFull, !post.frontmatter.image && NoImage]}>
              <SiteHeaderContent className="post-full-header">
                <SiteTitle className="post-full-title">{title}</SiteTitle>

                {
                  (booklink) ?
                    <BookLink href={booklink} >{book}</BookLink>
                   : <></>
                }

                <SiteQuote style={{textAlign:'center'}} className="site-quote">

                  {
                    post.frontmatter.author[0]
                  }
                </SiteQuote>

              </SiteHeaderContent>

              {!youtube && post.frontmatter.picture?.childImageSharp && (
                <PostFullImage>
                  <Img
                    objectFit="contain"
                    style={{ height: '100%', width: 'auto', }}
                    fluid={post.frontmatter.picture.childImageSharp.fluid}
                    alt={title}
                  />
                </PostFullImage>
              )}

 

  { youtube && (
              <div style={{width:'100%', marginBottom: 100}}>
        <center style={{maxWidth: 700, padding: 10, margin: 'auto', width: '100%', background:"rgb(19, 19, 19)", marginTop:0, marginBottom:0}}>

            <Link className="youtube-link" style={{ 
                width: '100%',
                textAlign: 'center',
                color: '#333 !important',
                boxShadow: 'none',
                padding: 5
            }} to={youtubeLink}>
            {title} on YouTube
            </Link>

          <iframe style={{border: '1px solid #333', background: '#000'}}  width="100%" height="425" src={youtube} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;fullscreen;" allowFullScreen></iframe>

        <br/>

        { (linkPrev || linkNext) ? (

        <div style={{display:'flex', width: '100%', flexWrap: 'wrap', padding: '10px 0'}}>
              {linkPrev && 
                (<div style={{ textAlign: 'left', paddingLeft: 20, paddingRight: 20, display: 'flex', flexDirection: 'column', flexBasis: '100%', flex: 1, whiteSpace: 'nowrap',
alignItems: 'center', padding: 10,
                }}>
                  <Link className="prev-next-link" style={{ 
                      width: '100%',
                      textAlign: 'center',
                      background: '#000',
                      padding: 5
                  }} to={linkPrev}>

                      <div style={{textAlign:'center',fontSize: 35, paddingBottom: 5}}> ⇜ </div> 
                  {titlePrev} </Link>
                 </div>
                )
              }


              {linkNext && 
                  (<div style={{ textAlign: 'right', paddingLeft: 20, paddingRight: 20, display: 'flex', flexDirection: 'column', flexBasis: '100%', flex: 1, whiteSpace: 'nowrap',
alignItems: 'center', padding: 10,
                  }}>
                    <Link className="prev-next-link" style={{
                      width: '100%',
                      textAlign: 'center',
                      background: '#000',
                      padding: 5
                    }} to={linkNext}>

                      <div style={{textAlign:'center',fontSize: 35, paddingBottom: 5}}> ⇝</div> 
                      {titleNext}
                    </Link>
                  </div>
                )
              }

            </div>

        ) : (<div><br/><br/></div>)}

          </center>
        </div>
      )
  }


              <PostContent htmlAst={post.htmlAst} />


        { (linkPrev || linkNext) ? (

        <div style={{display:'flex', width: '100%', flexWrap: 'wrap', padding: '10px 0'}}>
              {linkPrev && 
                (<div style={{ textAlign: 'left', paddingLeft: 20, paddingRight: 20, display: 'flex', flexDirection: 'column', flexBasis: '100%', flex: 1, whiteSpace: 'nowrap',
alignItems: 'center', padding: 10,
                }}>
                  <Link className="prev-next-link" style={{ 
                      width: '100%',
                      textAlign: 'center',
                      background: 'rgb(19, 19, 19)',
                      padding: 5
                  }} to={linkPrev}>

                      <div style={{textAlign:'center',fontSize: 35, paddingBottom: 5}}> ⇜ </div> 
                  {titlePrev} </Link>
                 </div>
                )
              }


              {linkNext && 
                  (<div style={{ textAlign: 'right', paddingLeft: 20, paddingRight: 20, display: 'flex', flexDirection: 'column', flexBasis: '100%', flex: 1, whiteSpace: 'nowrap',
alignItems: 'center', padding: 10,
                  }}>
                    <Link className="prev-next-link" style={{
                      width: '100%',
                      textAlign: 'center',
                      background: 'rgb(19, 19, 19)',
                      padding: 5
                    }} to={linkNext}>

                      <div style={{textAlign:'center',fontSize: 35, paddingBottom: 5}}> ⇝</div> 
                      {titleNext}
                    </Link>
                  </div>
                )
              }

            </div>

        ) : (<div><br/><br/></div>)}


              {/* The big email subscribe modal content */}
              {config.showSubscribe && <Subscribe title={config.title} />}

               <br/>
               <ShareButtons title={title} url={url} twitterHandle={twitterHandle} tags={tags}/>

               <br/>

            </article>
          </div>
        </main>


        <div css={[inner]}>
        <ReadNext
          currentPageSlug={location.pathname}
          tags={post.frontmatter.tags}
          relatedPosts={data.relatedPosts}
          pageContext={pageContext}
        />

         <Guides/>

        </div>


        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

const PostTemplate = css`
  .gatsby-resp-image-image {
    background: none !important;
    box-shadow: black 0px 0px 0px 400px inset !important;
  }

  .gatsby-resp-image-background-image {
    background: none !important;
    box-shadow: black 0px 0px 0px 400px inset !important;
  }

  .gatsby-resp-image-link {
    background: none !important;
    box-shadow: black 0px 0px 0px 400px inset !important;
  }

  .gatsby-resp-image-wrapper {
    background: none !important;
    box-shadow: black 0px 0px 0px 400px inset !important;
  }

  .site-main {
    margin-top: 64px;
    background: #fff;
    padding-bottom: 4vw;
  }

  //@media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  //}
`;

export const PostFull = css`
  position: relative;
  z-index: 50;
`;

export const NoImage = css`
  .post-full-content {
    padding-top: 0;
  }

  .post-full-content:before,
  .post-full-content:after {
    display: none;
  }
`;

export const PostFullHeader = styled.header`
  position: relative;
  margin: 0 auto;
  padding: 70px 170px 50px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  @media (max-width: 1170px) {
    padding: 60px 11vw 50px;
  }

  @media (max-width: 800px) {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  @media (max-width: 500px) {
    padding: 20px 0 35px;
  }
`;

const PostFullTags = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* color: var(--midgrey); */
  color: ${colors.midgrey};
  font-size: 1.3rem;
  line-height: 1.4em;
  font-weight: 600;
  text-transform: uppercase;
`;

const PostFullCustomExcerpt = styled.p`
  margin: 20px 0 0;
  color: var(--midgrey);
  font-family: Georgia, serif;
  font-size: 2.3rem;
  line-height: 1.4em;
  font-weight: 300;

  @media (max-width: 500px) {
    font-size: 1.9rem;
    line-height: 1.5em;
  }

  //@media (prefers-color-scheme: dark) {
    /* color: color(var(--midgrey) l(+10%)); */
    color: ${lighten('0.1', colors.midgrey)};
  //}
`;

const PostFullByline = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 35px 0 0;
  padding-top: 15px;
  /* border-top: 1px solid color(var(--lightgrey) l(+10%)); */
  border-top: 1px solid ${lighten('0.1', colors.lightgrey)};

  .post-full-byline-content {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
  }

  .post-full-byline-content .author-list {
    justify-content: flex-start;
    padding: 0 12px 0 0;
  }

  .post-full-byline-meta {
    margin: 2px 0 0;
    /* color: color(var(--midgrey) l(+10%)); */
    color: ${lighten('0.1', colors.midgrey)};
    font-size: 1.2rem;
    line-height: 1.2em;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  .post-full-byline-meta h4 {
    margin: 0 0 3px;
    font-size: 1.3rem;
    line-height: 1.4em;
    font-weight: 500;
  }

  .post-full-byline-meta h4 a {
    /* color: color(var(--darkgrey) l(+10%)); */
    color: ${lighten('0.1', colors.darkgrey)};
  }

  .post-full-byline-meta h4 a:hover {
    /* color: var(--darkgrey); */
    color: ${colors.darkgrey};
  }

  .post-full-byline-meta .bull {
    display: inline-block;
    margin: 0 4px;
    opacity: 0.6;
  }

  //@media (prefers-color-scheme: dark) {
    /* border-top-color: color(var(--darkmode) l(+15%)); */
    border-top-color: ${lighten('0.15', colors.darkmode)};

    .post-full-byline-meta h4 a {
      color: rgba(255, 255, 255, 0.75);
    }

    .post-full-byline-meta h4 a:hover {
      color: #fff;
    }
  //}
`;

export const PostFullTitle = styled.h1`
  margin: 0 0 0.2em;
  color: ${setLightness('0.05', colors.darkgrey)};
  @media (max-width: 500px) {
    margin-top: 0.2em;
    font-size: 3.3rem;
  }

  //@media (prefers-color-scheme: dark) {
    color: rgba(255, 255, 255, 0.9);
  //}
`;

const PostFullImage = styled.figure`
  margin: 25px 0 50px;
  height: 400px;
  background: ${colors.black} center center;
  background-size: cover;
  border-radius: 5px;
  object-fit: contain !important;
  text-align: center;

  .gatsby-image-wrapper {
    picture {
      img {
       object-fit: contain !important;
       filter: brightness(75%) !important;
      }
    }
  }

  @media (max-width: 1170px) {
    margin: 25px -6vw 50px;
    border-radius: 0;
    img {
      max-width: 1170px;
    }
  }

  @media (max-width: 800px) {
    height: 400px;
  }
  @media (max-width: 500px) {
    height: 350px;
  }
`;

export const query = graphql`
  query($slug: String, $primaryTag: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      htmlAst
      excerpt
      timeToRead
      frontmatter {
        type
        linkNext
        linkPrev
        youtube
        title
        userDate: date(formatString: "D MMMM YYYY")
        date
        tags
        excerpt
        parentPath
        picture {
          childImageSharp {
            fluid(maxHeight: 400) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        author
      }
    }
    relatedPosts: allMarkdownRemark(
      filter: { frontmatter: { tags: { in: [$primaryTag] }, draft: { ne: true } } }
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          timeToRead
          excerpt
          frontmatter {
            title
            date
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default PageTemplate;
