import { lighten } from 'polished';
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { colors } from '../styles/colors';


import { Link } from 'gatsby';

export interface SubscribeProps {
  title: string;
}

export const Guides = ({}) => {
  const tags = [
    {
      title: "Meditation",
      slug: "/topics/meditation/"
    },
    {
      title: "Consciousness",
      slug: "/topics/consciousness/"
    },
    {
      title: "Buddhism",
      slug: "/topics/buddhism/"
    },
    {
      title: "The Buddha",
      slug: "/topics/the-buddha/"
    },
    {
      title: "Free Will",
      slug: "/topics/free-will/"
    },
    {
      title: "Determinism",
      slug: "/topics/determinism/"
    },
    {
      title: "Indeterminism",
      slug: "topics/indeterminism/"
    },
    {
      title: "Emptiness",
      slug: "/topics/emptiness/"
    },
    {
      title: "All Topics",
      slug: "/topics/"
    },
  ];

  return (
    <GuideSection>
          <h3> Explore More </h3>

            <BList css={[]} style={{padding:0, paddingTop: 20}}>

            {tags.map((topic) => {
              // filter out drafts in production
              return (

                <li key={topic.slug}>
                  <Link  to={topic.slug} style={{color:"#aaa"}}>
                    {topic.title}
                  </Link>
                </li>
                )
            })}

            </BList>
    </GuideSection>
  )
};

const GuideSection = styled.aside`
  text-align:center;


`;

const BList = styled.ul`

  list-style-type:none;
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
  width: 100%;

  li {
    display: inline-block;
    width: 100%;
  }

  @media (max-width: 500px) {

    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
    text-align: center;
  }

`;
